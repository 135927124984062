import "core-js/modules/es6.regexp.match.js";
export default defineComponent({
  props: {
    sdgNumber: String,
    sdg: String,
    size: String
  },
  computed: {
    number: function number() {
      return this.sdgNumber.match(/\d+/g)[0];
    }
  }
});